import "react-app-polyfill/ie11"; // adds IE11/old Chrome support
import "react-app-polyfill/stable"; // adds Promise, Object.assign, etc

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DataProvider } from "./DataContext";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import ErrorBoundary from "./ErrorBoundary";

window.onerror = function (message, source, lineno, colno, error) {
  document.body.innerHTML =
    '<div style="color:red;font-size:20px;">JS Error: ' +
    message +
    " at " +
    source +
    ":" +
    lineno +
    ":" +
    colno +
    "</div>";
};

window.addEventListener("unhandledrejection", function (event) {
  document.body.innerHTML =
    '<div style="color:red;font-size:20px;">Unhandled Promise Rejection: ' +
    (event.reason?.message || event.reason) +
    "</div>";
});

try {
  ReactDOM.render(
    <React.StrictMode>
      <DataProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </DataProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} catch (err: any) {
  document.body.innerHTML =
    '<div style="color:red;font-size:20px;">Error: ' + err.message + "</div>";
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
