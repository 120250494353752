import { getBaseApiUrl } from "../helpers/Constants";
import { getGuid } from "../helpers/Utils";
import {
  CheckinVisitorRequest,
  CheckoutVisitorRequest,
  GetExistingVisitorRequest,
  GetExistingVisitorResponse,
  QuestionAnswer,
} from "../models/ClientLayoutCheckinModel";
import { VisitorType } from "../models/ModelTypes";

let apiUrl = getBaseApiUrl() + `ClientLayout/guid/${getGuid()}`;

const DEFAULT_TIMEOUT = 5000;

const fetchWithTimeout = async <T>(
  url: string,
  options: RequestInit
): Promise<T> => {
  const timeoutPromise = new Promise<never>((_, reject) =>
    setTimeout(() => reject(new Error("Request timed out")), DEFAULT_TIMEOUT)
  );

  const fetchPromise = fetch(url, options);

  const response = await Promise.race([fetchPromise, timeoutPromise]);

  if (!response.ok) {
    throw new Error(`Could not connect to server - Status: ${response.status}`);
  }

  try {
    return (await response.json()) as T;
  } catch (error) {
    // If JSON parsing fails (likely empty response), return empty object
    return {} as T;
  }
};

export const checkInVisitor = async (
  visitorType: VisitorType,
  hostClientId: number,
  visitorName: string,
  visitorPhoneNumber: string,
  visitorCompanyName: string,
  visitorSignatureBase64?: string,
  visitorQuestionAnswers?: QuestionAnswer[],
  accessExpirationDate?: Date,
  isRepeatVisitor?: boolean
): Promise<void> => {
  const checkInUrl = apiUrl + "/checkinVisitor";

  const requestBody: CheckinVisitorRequest = {
    visitorCompanyName,
    visitorName,
    visitorPhoneNumber,
    visitorType,
    hostClientId: hostClientId ?? null,
    visitorSignatureBase64,
    visitorQuestionAnswers: visitorQuestionAnswers,
    accessExpirationDate,
    isRepeatVisitor: isRepeatVisitor,
  };

  await fetchWithTimeout<void>(checkInUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Explicitly set the content type
    },
    body: JSON.stringify(requestBody),
  });
};

export const checkOutVisitor = async (
  visitorPhoneNumber: string
): Promise<void> => {
  const checkInUrl = apiUrl + "/checkoutVisitor";

  const requestBody: CheckoutVisitorRequest = {
    visitorPhoneNumber,
  };

  await fetchWithTimeout<void>(checkInUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Explicitly set the content type
    },
    body: JSON.stringify(requestBody),
  });
};

export const checkExistingVisitor = async (
  visitorPhoneNumber: string
): Promise<GetExistingVisitorResponse> => {
  const checkInUrl = apiUrl + "/existingVisitor";

  const requestBody: GetExistingVisitorRequest = {
    visitorPhoneNumber,
  };

  return await fetchWithTimeout<GetExistingVisitorResponse>(checkInUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Explicitly set the content type
    },
    body: JSON.stringify(requestBody),
  });
};
