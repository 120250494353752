import React, { useEffect, useState } from "react";
import { Booking } from "../../models/ClientLayoutModel";
import { getBookingsForDateSorted, parseCssSafely } from "../../helpers/Utils";
import { useDataContext } from "../../DataContext";
import { RoomAvailabilityStatus } from "../../models/ModelTypes";

interface RoomAvailabilityProps {}

const RoomAvailability: React.FC<RoomAvailabilityProps> = () => {
  const [, setCurrentTime] = useState(Date.now());
  const { getData } = useDataContext();

  useEffect(() => {
    const interval = setInterval(() => setCurrentTime(Date.now()), 1000); // Update every second
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const getAvailabilityStatus = (bookings: Booking[]) => {
    const now = new Date();
    const fifteenMinutes = 15 * 60 * 1000; // 15 minutes in ms

    // 1. Check for an active booking.
    const activeBooking = bookings.find(
      (booking) => new Date(booking.start) <= now && new Date(booking.end) > now
    );
    if (activeBooking) {
      return {
        status: getData().infoStyle.roomAvailabilityOccupiedString, // e.g. "Optaget"
        details: (
          <>
            <div
              style={JSON.parse(getData().infoStyle.bookingTitleCss ?? "{}")}
            >
              {activeBooking.title}
            </div>
            <div style={JSON.parse(getData().infoStyle.bookingTimeCss ?? "{}")}>
              {new Date(activeBooking.start).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              -{" "}
              {new Date(activeBooking.end).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </>
        ),
        availabilityStatus: RoomAvailabilityStatus.Occupied,
      };
    }

    // 2. If not active, look for the next booking.
    const nextBooking = bookings.find(
      (booking) => new Date(booking.start) > now
    );
    if (nextBooking) {
      const startTime = new Date(nextBooking.start);
      const diff = startTime.getTime() - now.getTime();
      if (diff <= fifteenMinutes) {
        return {
          status: getData().infoStyle.roomAvailabilitySoonOccupiedString, // e.g. "Snart optaget"
          details: (
            <>
              <div
                style={JSON.parse(getData().infoStyle.bookingTitleCss ?? "{}")}
              >
                {nextBooking.title}
              </div>
              <div
                style={JSON.parse(getData().infoStyle.bookingTimeCss ?? "{}")}
              >
                {startTime.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                -{" "}
                {new Date(nextBooking.end).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </>
          ),
          availabilityStatus: RoomAvailabilityStatus.SoonOccupied,
        };
      } else {
        // There is a next booking but it's not within 15 minutes.
        return {
          status: getData().infoStyle.roomAvailabilityAvailableString, // e.g. "Ledig"
          details: (
            <>
              <div
                style={JSON.parse(getData().infoStyle.bookingTitleCss ?? "{}")}
              >
                {nextBooking.title}
              </div>
              <div
                style={JSON.parse(getData().infoStyle.bookingTimeCss ?? "{}")}
              >
                {startTime.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                -{" "}
                {new Date(nextBooking.end).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
            </>
          ),
          availabilityStatus: RoomAvailabilityStatus.Available,
        };
      }
    }

    // 3. If there are no bookings left today, return "Rest of Day" status.
    return {
      status: getData().infoStyle.roomAvailabilityAvailableRestOfDayString, // e.g. "Ledig resten af dagen"
      details: "",
      availabilityStatus: RoomAvailabilityStatus.AvailableRestOfDay,
    };
  };

  const calculateGridDimensions = (totalItems: number) => {
    // Ensure a minimum grid of 2x2, even if there are only 1-4 items.
    const columns = Math.max(Math.ceil(Math.sqrt(totalItems)), 2);
    const rows = Math.max(Math.ceil(totalItems / columns), 2);
    return { columns, rows };
  };

  const { columns, rows } = calculateGridDimensions(getData().calendars.length);

  return (
    <div
      className="room-availability-container"
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridTemplateRows: `repeat(${rows}, 1fr)`,
      }}
    >
      {getData().calendars.map((calendar, index) => {
        const bookingsForToday = getBookingsForDateSorted(
          today.toString(),
          calendar.id
        );
        const availability = getAvailabilityStatus(bookingsForToday);

        return (
          <div
            key={calendar.id}
            className="room-availability-item"
            style={{
              gridColumn:
                getData().calendars.length === 5 && index >= 3
                  ? "span 1 / auto"
                  : undefined,
              ...(availability.availabilityStatus ===
              RoomAvailabilityStatus.Occupied
                ? parseCssSafely(
                    getData().infoStyle.roomAvailabilityOccupiedBackgroundCss
                  )
                : availability.availabilityStatus ===
                  RoomAvailabilityStatus.SoonOccupied
                ? parseCssSafely(
                    getData().infoStyle
                      .roomAvailabilitySoonOccupiedBackgroundCss
                  )
                : availability.availabilityStatus ===
                  RoomAvailabilityStatus.AvailableRestOfDay
                ? parseCssSafely(
                    getData().infoStyle
                      .roomAvailabilityAvailableRestOfDayBackgroundCss
                  )
                : parseCssSafely(
                    getData().infoStyle.roomAvailabilityAvailableBackgroundCss
                  )),
            }}
          >
            <div
              className="room-title"
              style={JSON.parse(getData().infoStyle.calendarTitleCss ?? "{}")}
            >
              {calendar.name}
            </div>
            <div className="room-status">
              <div className="status-text">{availability.status}</div>
              {availability.details && (
                <div className="details-text">{availability.details}</div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RoomAvailability;
