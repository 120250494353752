import React, { createContext, useContext, useState } from "react";
import { ClientLayout } from "./models/ClientLayoutModel";
import dataStore from "./helpers/DataStore";
import { Language } from "./models/ModelTypes";

interface DataContextType {
  data: ClientLayout;
  language: Language;
  currentStepId: number;
  setData: (newData: ClientLayout) => void;
  setLanguage: (newLanguage: Language) => void;
  setCurrentStepId: (stepId: number) => void;
  getData: () => ClientLayout;
  getLanguage: () => Language;
  getCurrentStepId: () => number;
}

const DataContext = createContext<DataContextType | undefined>(undefined);

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<ClientLayout>({} as ClientLayout);
  const [language, setLanguage] = useState<Language>(Language.Danish);
  const [currentStepId, setCurrentStepId] = useState<number>(0);

  const getData = (): ClientLayout => {
    return data;
  };

  const getLanguage = (): Language => {
    return language;
  };

  const getCurrentStepId = (): number => {
    return currentStepId;
  };

  const updateData = (newData: ClientLayout) => {
    dataStore.setData(newData); // Update the global store
    setData(newData);
  };

  const updateLanguage = (newLanguage: Language) => {
    dataStore.setLanguage(newLanguage); // Update the global store
    setLanguage(newLanguage);
  };

  const updateCurrentStepId = (stepId: number) => {
    setCurrentStepId(stepId);
    dataStore.setCurrentStepId(stepId);
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData: updateData,
        language,
        setLanguage: updateLanguage,
        currentStepId,
        setCurrentStepId: updateCurrentStepId,
        getData,
        getLanguage,
        getCurrentStepId,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
