import { FieldValueContext } from "../interfaces/FieldValueContext";
import {
  Booking,
  Calendar,
  Client,
  ClientGroup,
  ClientGroupWayfinding,
  FieldToShow,
} from "../models/ClientLayoutModel";
import {
  WayfindingBuilding,
  WayfindingFloor,
  WayfindingLocation,
  WayfindingSite,
} from "../models/ClientLayoutWayfindingModel";
import { SourceType, WayfindingOrientation } from "../models/ModelTypes";
import dataStore from "./DataStore";
import staticDataStore from "./DataStore";
import { isBookingOnDate } from "./DateHelper";
import arrow_forward from "../images/arrow_forward.png";
import arrow_backward from "../images/arrow_backward.png";
import arrow_left from "../images/arrow_left.png";
import arrow_right from "../images/arrow_right.png";

let refreshId: NodeJS.Timeout;

export const getGuid = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const guid = queryParams.get("layoutid");
  return guid;
};

export const refreshAt = (hours: number, minutes: number, seconds: number) => {
  if (refreshId) return;
  var now = new Date();
  var then = new Date();

  if (
    now.getHours() > hours ||
    (now.getHours() === hours && now.getMinutes() > minutes) ||
    (now.getHours() === hours &&
      now.getMinutes() === minutes &&
      now.getSeconds() >= seconds)
  ) {
    then.setDate(now.getDate() + 1);
  }
  then.setHours(hours);
  then.setMinutes(minutes);
  then.setSeconds(seconds);

  var timeout = then.getTime() - now.getTime();
  refreshId = setTimeout(function () {
    window.location.reload();
  }, timeout);
};

export const cancelRefreshAt = () => {
  if (refreshId) clearTimeout(refreshId);
};

export const getFieldValue = (
  field: FieldToShow,
  context: FieldValueContext
): string => {
  const { booking, calendar, date, client, clientGroup } = context;

  let returnValue;
  switch (field.sourceType) {
    case SourceType.Calendar:
      returnValue = getCalendarField(field.fieldName, calendar);
      break;
    case SourceType.Client:
      returnValue = getClientField(field.fieldName, client);
      break;
    case SourceType.Booking:
      returnValue = getBookingField(field.fieldName, booking, date);
      break;
    case SourceType.CustomCalendarField:
      returnValue =
        calendar?.customFields.find((f) => f.name === field.fieldName)?.value ??
        "";
      break;
    case SourceType.CustomBookingField:
      returnValue =
        booking?.customFields.find((f) => f.name === field.fieldName)?.value ??
        "";
      break;
    case SourceType.ClientGroup:
      returnValue = getClientGroupField(field.fieldName, clientGroup);
      break;
    default:
      returnValue = "";
  }
  return (
    (returnValue && (field.valuePrefix ?? "")) +
    returnValue.trim() +
    (returnValue && (field.valuePostfix ?? ""))
  );
};

const getCalendarField = (fieldName: string, calendar?: Calendar): string => {
  switch (fieldName) {
    case "Title":
      return calendar?.name ?? "";
    default:
      return "";
  }
};

const getClientField = (fieldName: string, client?: Client): string => {
  switch (fieldName) {
    case "Name":
      return client?.name ?? "";
    case "Phone":
      return client?.phoneNumber ?? "";
    case "Image":
      return client?.imageBase64 ?? "";
    case "JobTitle":
      return client?.jobTitle ?? "";
    default:
      return "";
  }
};

const getBookingField = (
  fieldName: string,
  booking?: Booking,
  date?: string
): string => {
  switch (fieldName) {
    case "Title":
      return booking?.title ?? "";
    case "Time":
      const { startTime, endTime } = getStartAndEndTimeWithRespectOfSpans(
        booking ?? ({} as Booking),
        date ?? ""
      );
      return staticDataStore.getData().hideEndTime
        ? startTime
        : `${startTime} - ${endTime}`;
    case "Date":
      return new Date(booking?.start ?? "").toLocaleDateString("da-DK", {
        day: "2-digit",
        month: "short",
      });
    case "DateRange":
      const startDate = new Date(booking?.start ?? "").toLocaleDateString(
        "da-DK",
        {
          day: "2-digit",
          month: "2-digit",
        }
      );
      const endDate = new Date(booking?.end ?? "").toLocaleDateString("da-DK", {
        day: "2-digit",
        month: "2-digit",
      });
      return startDate + " - " + endDate;
    case "Person":
      return booking?.person ?? "";
    case "Description":
      return booking?.description ?? "";
    default:
      return "";
  }
};

const getClientGroupField = (
  fieldName: string,
  clientGroup?: ClientGroup
): string => {
  let primaryLocation: ClientGroupWayfinding | undefined;
  let location: WayfindingLocation | undefined;
  let floor: WayfindingFloor | undefined;
  let building: WayfindingBuilding | undefined;
  let site: WayfindingSite | undefined;
  switch (fieldName) {
    case "Title":
      return clientGroup?.name ?? "";
    case "Logo":
      return clientGroup?.logoImageBase64 ?? "";
    case "Site":
      primaryLocation = clientGroup?.wayfindingLocations.find(
        (loc) => loc.isPrimaryLocation
      );

      if (!primaryLocation) return "";

      location = staticDataStore
        .getData()
        .wayfindingLocations.find(
          (loc) => loc.id === primaryLocation?.wayfindingLocationId
        );

      if (!location) return "";

      floor = staticDataStore
        .getData()
        .wayfindingFloors.find((f) => f.id === location?.wayfindingFloorId);

      if (!floor) return "";

      building = staticDataStore
        .getData()
        .wayfindingBuildings.find((b) => b.id === floor?.wayfindingBuildingId);

      if (!building) return "";

      site = staticDataStore
        .getData()
        .wayfindingSites.find((s) => s.id === building?.wayfindingSiteId);
      return site?.title ?? "";

    case "Building":
      primaryLocation = clientGroup?.wayfindingLocations.find(
        (loc) => loc.isPrimaryLocation
      );

      if (!primaryLocation) return "";

      location = staticDataStore
        .getData()
        .wayfindingLocations.find(
          (loc) => loc.id === primaryLocation?.wayfindingLocationId
        );

      if (!location) return "";

      floor = staticDataStore
        .getData()
        .wayfindingFloors.find((f) => f.id === location?.wayfindingFloorId);

      if (!floor) return "";

      building = staticDataStore
        .getData()
        .wayfindingBuildings.find((b) => b.id === floor?.wayfindingBuildingId);

      return building?.title ?? "";

    case "Floor":
      primaryLocation = clientGroup?.wayfindingLocations.find(
        (loc) => loc.isPrimaryLocation
      );

      if (!primaryLocation) return "";

      const floorLocation = staticDataStore
        .getData()
        .wayfindingLocations.find(
          (loc) => loc.id === primaryLocation?.wayfindingLocationId
        );

      if (!floorLocation) return "";

      floor = staticDataStore
        .getData()
        .wayfindingFloors.find(
          (floor) => floor.id === floorLocation.wayfindingFloorId
        );

      return floor?.title ?? "";

    case "Locations":
      return (
        clientGroup?.wayfindingLocations
          .map((wfLocation) => {
            const location = staticDataStore
              .getData()
              .wayfindingLocations.find(
                (loc) => loc.id === wfLocation.wayfindingLocationId
              );
            return location?.title;
          })
          .filter((title): title is string => !!title)
          .join("<br>") ?? ""
      );

    case "PrimaryLocation":
      const primaryLocationId = clientGroup?.wayfindingLocations.find(
        (wfLoc) => wfLoc.isPrimaryLocation
      )?.wayfindingLocationId;

      const primaryLocationTitle = staticDataStore
        .getData()
        .wayfindingLocations.find((loc) => loc.id === primaryLocationId)?.title;

      return primaryLocationTitle ?? "";
    case "WayfindingOrientation":
      const orientation = clientGroup?.defaultWayfindingOrientationOnScreens;

      switch (orientation) {
        case WayfindingOrientation.Forward:
          return arrow_forward;

        case WayfindingOrientation.Backward:
          return arrow_backward;

        case WayfindingOrientation.Left:
          return arrow_left;

        case WayfindingOrientation.Right:
          return arrow_right;

        default:
          return "";
      }
    default:
      return "Unknown field " + fieldName + " on client";
  }
};

export const getStartAndEndTimeWithRespectOfSpans = (
  booking: Booking,
  date: string
) => {
  let startDateTime: Date, endDateTime: Date;

  const bookingStartDate = new Date(booking.start).toISOString().split("T")[0];
  const bookingEndDate = new Date(booking.end).toISOString().split("T")[0];

  // Apply the logic to determine the correct times
  if (bookingStartDate === date && bookingEndDate !== date) {
    // First day: use the actual start time and set the end time to 23:59
    startDateTime = new Date(booking.start);
    endDateTime = new Date(booking.start);
    endDateTime.setHours(23, 59);
  } else if (bookingStartDate !== date && bookingEndDate === date) {
    // Last day: set the start time to 00:00 and use the actual end time
    startDateTime = new Date(booking.end);
    startDateTime.setHours(0, 0);
    endDateTime = new Date(booking.end);
  } else if (bookingStartDate !== date && bookingEndDate !== date) {
    // Intermediate days: set start time to 00:00 and end time to 23:59
    startDateTime = new Date(date); // current date
    startDateTime.setHours(0, 0);
    endDateTime = new Date(date); // current date
    endDateTime.setHours(23, 59);
  } else {
    // Single day booking
    startDateTime = new Date(booking.start);
    endDateTime = new Date(booking.end);
  }
  var startTime = startDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  var endTime = endDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  return { startTime, endTime };
};

export const getBookingsForDateSorted = (
  date: string,
  calendarId: number | undefined = undefined,
  excludeExpired = true,
  isInfoscreenInvoking = true
): Booking[] => {
  const currentDate = new Date();
  const isToday = new Date(date).toDateString() === currentDate.toDateString();
  let filteredBookings = staticDataStore
    .getData()
    .bookings.filter((booking) => {
      const isRelatedToCalendarIfExist_OtherwiseTrue = calendarId
        ? booking.calendarId === calendarId
        : true;

      const bookingStart = new Date(booking.start);
      const bookingEnd = new Date(booking.end);

      // Only include bookings on the specified date and not yet ended if it's today
      const isOnDate = isBookingOnDate(bookingStart, bookingEnd, date);
      const isTodayAndHasNotEnded_OtherwiseTrue =
        isToday && excludeExpired ? bookingEnd > currentDate : true;
      const isInfoscreenInvokingAndBookingIsAllowedOnInfoscreens =
        isInfoscreenInvoking && !booking.omitFromInfoscreens;
      return (
        isOnDate &&
        isTodayAndHasNotEnded_OtherwiseTrue &&
        isRelatedToCalendarIfExist_OtherwiseTrue &&
        isInfoscreenInvokingAndBookingIsAllowedOnInfoscreens
      );
    });

  filteredBookings.sort((a: Booking, b: Booking) => {
    const startDiff = new Date(a.start).getTime() - new Date(b.start).getTime();
    if (startDiff !== 0) {
      return startDiff; // If `start` times are different, sort by `start`
    }
    // If `start` times are the same, sort by `end` time
    return new Date(a.end).getTime() - new Date(b.end).getTime();
  });

  if (
    staticDataStore.getData().maximumNumberOfEvents &&
    (staticDataStore.getData().maximumNumberOfEvents as number) > 0
  )
    filteredBookings = filteredBookings.slice(
      0,
      staticDataStore.getData().maximumNumberOfEvents
    );

  return filteredBookings;
};

/**
 * Fetch a translation for a specific key and language.
 *
 * @param key - The key of the translation to fetch.
 * @param key - The value to fall back to.
 * @returns The value of the translation or fallback value if not found.
 */
export const getTranslation = (key: string, fallback: string = ""): string => {
  const translations = staticDataStore.getData()?.checkinStepTranslations;
  if (!translations) return "";

  const currentStepId = staticDataStore.getCurrentStepId();

  // Try to find a translation specific to the current step
  let translation = translations.find(
    (t) =>
      t.language === staticDataStore.getLanguage() &&
      t.key === key &&
      t.stepId === currentStepId
  );

  // Otherwise try to find default translation for key
  if (!translation)
    translation = translations.find(
      (t) =>
        t.language === staticDataStore.getLanguage() &&
        t.key === key &&
        t.stepId === 0
    );

  let textWithReplacedPlaceholders = replacePlaceholderValues(
    translation?.value ?? fallback
  );
  return textWithReplacedPlaceholders;
};

const replacePlaceholderValues = (text: string): string => {
  if (text.includes("{name_of_host}"))
    text = text.replace("{name_of_host}", dataStore.getSelectedHost().name);
  return text;
};

/**
 * Fetch a style value for a specific key from a step.
 *
 * @param key - The key of the style to fetch.
 * @returns The value of the style or `null` if not found.
 */
export const getStepStyle = (key: string, fallback: any = {}): any => {
  const styles = staticDataStore.getData()?.checkinStepStyles;
  if (!styles) return "{}";
  const style = styles.find((s) => s.key === key);
  return parseCssSafely(style?.value, fallback);
};

/**
 * Parse a CSS string safely to a JSON object.
 *
 * @param cssString - The CSS string to parse.
 * @param key - The value to fall back to.
 * @returns The parsed JSON object or the fallback value if parsing fails.
 */
export const parseCssSafely = (
  cssString: string | undefined,
  fallback: any = {}
) => {
  try {
    return cssString ? JSON.parse(cssString) : fallback;
  } catch (error) {
    return fallback;
  }
};

export const getPaginationString = (
  currentPageIndex: number,
  totalPages: number
): string => {
  return dataStore
    .getData()
    .infoStyle.eventPaginationString.replace(
      "{index}",
      (currentPageIndex + 1).toString()
    )
    .replace("{total}", totalPages.toString());
};
