import React from "react";
import { useDataContext } from "../../DataContext";
import {
  getFieldValue,
  parseCssSafely,
  getBookingsForDateSorted,
} from "../../helpers/Utils";
import flagImage from "../../images/flag.png"; // This assumes flag.png exists in the images folder
import "./SpecialDays.css";
import { Booking, FieldToShow } from "../../models/ClientLayoutModel";
import { getDateRange, isBookingOnDate } from "../../helpers/DateHelper";

const SpecialDays: React.FC = () => {
  const { infoStyle, fieldNamesToDisplay, calendars, bookings } =
    useDataContext().getData();

  // Find the image field
  const imageField =
    fieldNamesToDisplay.find((field) => field.fieldName === "Image") ??
    ({} as FieldToShow);

  // Get today's date in ISO format (YYYY-MM-DD)
  const today = new Date().toISOString().split("T")[0];

  // Get bookings for today
  const todayBookings = getBookingsForDateSorted(
    new Date().toISOString(),
    0,
    true
  );

  // Get upcoming dates with bookings
  const upcomingBookings = getDateRange()
    .filter((date) => date > today)
    .map((date) => ({
      date,
      bookings: bookings.filter((booking) =>
        isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
      ),
    }))
    .filter((item) => item.bookings.length > 0);

  const increaseFontSize = (
    fontSize: string | undefined,
    increaseFactor: number = 1.3
  ) => {
    if (!fontSize) return "2.5rem"; // Default to 2.5rem

    const match = fontSize.match(/^([\d.]+)(px|rem|em|%)?$/);
    if (!match) return fontSize; // If the format is unexpected, return as-is

    const [, numericValue, unit] = match;
    const increasedSize = parseFloat(numericValue) * increaseFactor;

    return unit ? `${increasedSize}${unit}` : `${increasedSize}px`; // Default to px if no unit
  };

  // Render booking fields (reused for both today and upcoming bookings)
  const renderBookingFields = (booking: Booking, date: string) =>
    fieldNamesToDisplay
      .filter((field) => field.fieldName !== "Image")
      .map((field) => {
        const valueFieldStyles = parseCssSafely(field.valueFieldCss);
        const adjustedFontSize = increaseFontSize(valueFieldStyles.fontSize);

        return (
          <>
            <div style={parseCssSafely(field.headerFieldCss)}>
              {field.displayName}:
            </div>
            <div
              style={{
                ...valueFieldStyles,
                fontSize: adjustedFontSize,
              }}
            >
              {getFieldValue(field, {
                booking,
                calendar:
                  calendars.find((c) => c.id === booking.calendarId) ??
                  calendars[0],
                date,
              })}
            </div>
          </>
        );
      });

  return (
    <div
      className="app"
      style={{
        backgroundImage: infoStyle?.backgroundImageBase64
          ? `url(${infoStyle.backgroundImageBase64})`
          : undefined,
      }}
    >
      <div
        className="columns-container"
        style={parseCssSafely(infoStyle.backgroundCss)}
      >
        {/* Today's title - only shown when there are bookings today */}
        {todayBookings.length > 0 && (
          <div style={parseCssSafely(infoStyle.layoutNameCss)}>TILLYKKE</div>
        )}

        {/* Today's special days */}
        {todayBookings.map((booking) => (
          <div
            key={booking.id}
            style={parseCssSafely(infoStyle.bookingContainerCss)}
          >
            {renderBookingFields(booking, today)}
            <img
              src={flagImage}
              alt="flag"
              style={parseCssSafely(imageField.valueFieldCss)}
            />
          </div>
        ))}

        {/* Upcoming section header */}
        {upcomingBookings.length > 0 && (
          <div
            style={{
              ...parseCssSafely(infoStyle.layoutNameCss),
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {/* Todo: add field somewhere in db */}
            ...senere fødselsdage
          </div>
        )}

        {/* Upcoming special days */}
        {upcomingBookings.map((dateItem) =>
          // Render each date's bookings
          dateItem.bookings.map((booking) => (
            <div
              key={`${dateItem.date}-${booking.id}`}
              style={{
                ...parseCssSafely(infoStyle.bookingContainerCss),
              }}
            >
              {/* Date column */}
              <div style={parseCssSafely(infoStyle.dayHeaderDateCss)}>
                {new Date(dateItem.date).toLocaleDateString("da-DK", {
                  day: "2-digit",
                  month: "2-digit",
                })}
              </div>

              {/* field column */}
              {fieldNamesToDisplay
                .filter((field) => field.fieldName !== "Image")
                .map((field) => (
                  <div
                    key={field.fieldName}
                    style={parseCssSafely(field.valueFieldCss)}
                  >
                    {getFieldValue(field, {
                      booking,
                      calendar:
                        calendars.find((c) => c.id === booking.calendarId) ??
                        calendars[0],
                      date: dateItem.date,
                    })}
                  </div>
                ))}

              {/* Image column */}
              <img
                src={flagImage}
                alt="flag"
                style={parseCssSafely(imageField.valueFieldCss)}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SpecialDays;
