export enum ClientLayoutInfoType {
  RowsWithTiles = "RowsWithTiles",
  ColumnsAndRow = "ColumnsAndRow",
  RowsWithTables = "RowsWithTables",
  SingleEvent = "SingleEvent",
  MenuOfTheWeek = "MenuOfTheWeek",
  RoomAvailability = "RoomAvailability",
  BookingClients = "BookingClients",
  CalendarDayView = "CalendarDayView",
  SpecialDays = "SpecialDays",
  WayfindingClientGroupsOverview = "WayfindingClientGroupsOverview",
  BookingList = "BookingList",
}

export enum ClientLayoutHeaderDatasetType {
  Calendar = "Calendar",
  Day = "Day",
  Booking = "Booking",
  BookingCustomFields = "BookingCustomFields",
}

export enum SourceType {
  Calendar = "Calendar",
  CustomCalendarField = "CustomCalendarField",
  Booking = "Booking",
  CustomBookingField = "CustomBookingField",
  Client = "Client",
  ClientGroup = "ClientGroup",
}

export enum ClientLayoutType {
  Info = "Info",
  Checkin = "Checkin",
  MeetingRoom = "MeetingRoom",
  Touch = "Touch",
  Wayfinding = "Wayfinding",
}

export enum IntegrationType {
  None = "None",
  Outlook = "Outlook",
  Dbu = "Dbu",
  Exchange = "Exchange",
  ICal = "ICal",
  Google = "Google",
  ActiveDirectory = "ActiveDirectory",
  Picasso = "Picasso",
  Globus = "Globus",
  Kmd = "Kmd",
  FileZilla = "FileZilla",
  Winkas = "Winkas",
  Yesplan = "Yesplan",
  Pronestor = "Pronestor",
  FtpExternal = "FtpExternal",
  Spectra = "Spectra",
  Relesys = "Relesys",
}

export enum Language {
  Danish = "Danish",
  English = "English",
  German = "German",
  Polish = "Polish",
}

export enum CheckinActionType {
  NavigateToStep = "NavigateToStep",
  ShowModal = "ShowModal",
  TriggerEvent = "TriggerEvent",
  CheckIn = "CheckIn",
  CheckOut = "CheckOut",
  CheckInRepeatVisitor = "CheckInRepeatVisitor",
}

export enum CheckinStepType {
  EntrySelectClientGroupHorizontalImages = "Entry_select_client_group_horizontal_images",
  SelectClientPortraitCards = "Select_client_portrait_cards",
  SelectVisitorType = "Select_visitor_type",
  SelectClientGroupGridView = "Select_client_group_grid_view",
  EnterVisitorPhoneNumber = "Enter_visitor_phone_number",
  EnterVisitorName = "Enter_visitor_name",
  EnterCheckoutPhoneNumber = "Enter_checkout_phone_number",
  SignDocument = "Sign_document",
  QuestionAndAnswer = "Question_and_answer",
  ShowConditionsPdf = "Show_conditions_pdf",
  EnterAppointmentVisitDuration = "Enter_appointment_visit_duration",
  EnterVisitorCompanyName = "Enter_visitor_company_name",
}

export enum VisitorType {
  Visitor = "Visitor",
  Courier = "Courier",
  VisitorNoAppointment = "VisitorNoAppointment",
  Service = "Service",
}

export enum RoomAvailabilityStatus {
  Occupied,
  SoonOccupied,
  Available,
  AvailableRestOfDay,
}

export enum FieldType {
  String = "String",
  Image = "Image",
}

export enum WayfindingOrientation {
  Forward = "Forward",
  Backward = "Backward",
  Left = "Left",
  Right = "Right",
}

// Printer types
export enum PrinterType {
  GdiPrinter = "GdiPrinter",
  ZplPrinter = "ZplPrinter",
}

export enum PrinterSourceType {
  Text = "Text",
  Image = "Image",
}

export enum PrinterFont {
  Verdana = "Verdana",
  TimesNewRoman = "TimesNewRoman",
}

export enum KnownColor {
  Black = "Black",
  White = "White",
  Red = "Red",
  Green = "Green",
  Blue = "Blue",
  Yellow = "Yellow",
}

export enum StringFormatFlags {
  LineLimit = "LineLimit",
  NoClip = "NoClip",
  NoWrap = "NoWrap",
}

export enum HorizontalAlignment {
  Near = "Near",
  Center = "Center",
  Far = "Far",
}

export enum VerticalAlignment {
  Near = "Near",
  Center = "Center",
  Far = "Far",
}

export enum ConnectionType {
  Usb = "Usb",
  Network = "Network",
  None = "None",
}
