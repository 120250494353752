import { Client, ClientGroup, ClientLayout } from "../models/ClientLayoutModel";
import { Language, VisitorType } from "../models/ModelTypes";

interface QuestionAnswer {
  stepQuestionId: number;
  answeredYes: boolean;
  userInput?: string;
}

class DataStore {
  private data: ClientLayout = {} as ClientLayout;

  private language: Language = Language.Danish;

  private selectedClient: Client = {} as Client;
  private selectedClientGroup: ClientGroup = {} as ClientGroup;

  private visitorType: VisitorType = VisitorType.Visitor;
  private visitorName: string = "";
  private visitorPhoneNumber: string = "";
  private visitorCompanyName: string = "";
  private currentStepId: number = 0;
  private signatureBase64: string = "";
  private questionAnswers: QuestionAnswer[] = [];
  private accessExpirationDate: Date | undefined = undefined;
  private isRepeatVisitor: boolean = false;

  setData(newData: ClientLayout) {
    this.data = newData;
  }

  getData(): ClientLayout {
    return this.data;
  }

  setLanguage(newLanguage: Language) {
    this.language = newLanguage;
  }

  getLanguage(): Language {
    return this.language;
  }

  setSelectedHost(client: Client) {
    this.selectedClient = client;
  }

  getSelectedHost() {
    return this.selectedClient;
  }

  setSelectedClientGroup(clientGroup: ClientGroup) {
    this.selectedClientGroup = clientGroup;
  }

  getSelectedClientGroup() {
    return this.selectedClientGroup;
  }

  setVisitorType(type: VisitorType) {
    this.visitorType = type;
  }

  getVisitorType() {
    return this.visitorType;
  }

  setVisitorName(name: string) {
    this.visitorName = name;
  }

  getVisitorName() {
    return this.visitorName;
  }

  setVisitorPhoneNumber(number: string) {
    this.visitorPhoneNumber = number;
  }

  getVisitorPhoneNumber() {
    return this.visitorPhoneNumber;
  }

  setVisitorCompanyName(companyName: string) {
    this.visitorCompanyName = companyName;
  }

  getVisitorCompanyName() {
    return this.visitorCompanyName;
  }

  setCurrentStepId(stepId: number) {
    this.currentStepId = stepId;
  }

  getCurrentStepId(): number | null {
    return this.currentStepId;
  }

  resetVisitorData() {
    this.selectedClient = {} as Client;
    this.selectedClientGroup = {} as ClientGroup;
    this.visitorType = VisitorType.Visitor;
    this.visitorCompanyName = "";
    this.visitorName = "";
    this.visitorPhoneNumber = "";
    this.signatureBase64 = "";
    this.questionAnswers = [];
    this.accessExpirationDate = undefined;
    this.isRepeatVisitor = false;
  }

  setSignatureBase64(signatureBase64: string) {
    this.signatureBase64 = signatureBase64;
  }

  getSignatureBase64(): string {
    return this.signatureBase64;
  }

  setQuestionAnswer(answer: QuestionAnswer) {
    const existingIndex = this.questionAnswers.findIndex(
      (a) => a.stepQuestionId === answer.stepQuestionId
    );

    if (existingIndex >= 0) {
      this.questionAnswers[existingIndex] = answer;
    } else {
      this.questionAnswers.push(answer);
    }
  }

  getQuestionAnswers() {
    return this.questionAnswers;
  }

  getQuestionAnswer(stepQuestionId: number) {
    return this.questionAnswers.find(
      (a) => a.stepQuestionId === stepQuestionId
    );
  }

  setAccessExpirationDate(accessExpirationDate: Date) {
    this.accessExpirationDate = accessExpirationDate;
  }

  getAccessExpirationDate() {
    return this.accessExpirationDate;
  }

  public setIsRepeatVisitor(isRepeat: boolean): void {
    this.isRepeatVisitor = isRepeat;
  }

  public getIsRepeatVisitor(): boolean {
    return this.isRepeatVisitor;
  }
}

const dataStore = new DataStore();
export default dataStore;
