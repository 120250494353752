import React from "react";
import { useDataContext } from "../../DataContext";
import { FieldType } from "../../models/ModelTypes";
import { getFieldValue, parseCssSafely } from "../../helpers/Utils";
import { FieldToShow } from "../../models/ClientLayoutModel";
import "./BookingList.css";

interface BookingListProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

const BookingList: React.FC<BookingListProps> = ({ scrollRef }) => {
  const data = useDataContext().getData();
  // Initialize groupedFields as an array of empty arrays with the correct size
  const groupedFields: { fields: FieldToShow[] }[] = new Array(
    Math.max(...data.fieldNamesToDisplay.map((field) => field.order)) + 1
  )
    .fill(null)
    .map(() => ({ fields: [] }));

  // Group fields by their order
  data.fieldNamesToDisplay.forEach((field) => {
    groupedFields[field.order].fields.push(field);
  });
  return (
    <div
      className="app"
      style={{
        backgroundImage: data.infoStyle?.backgroundImageBase64
          ? `url(${data.infoStyle.backgroundImageBase64})`
          : undefined,
      }}
    >
      <div
        className={
          data.infoStyle.autoscrollOnVerticalOverflow
            ? "scrollable-content"
            : ""
        }
        style={JSON.parse(data.infoStyle.backgroundCss ?? "{}")}
        ref={scrollRef}
      >
        <div className="booking-list-headers-container">
          {data.fieldNamesToDisplay.map((field) => {
            return (
              <div
                className="booking-list-header"
                key={field.fieldName}
                style={parseCssSafely(field.headerFieldCss)}
              >
                {field.displayName}
              </div>
            );
          })}
        </div>
        <div className="booking-list-values-container">
          {data.bookings.map((booking) => (
            <div key={booking.id} className="booking-list-value-group">
              {groupedFields.map((fieldGroup, index) => (
                <div key={index} className="booking-list-values">
                  {fieldGroup.fields.map((field) => {
                    if (field.fieldType === FieldType.String) {
                      return (
                        <div
                          className="booking-list-value"
                          key={`${booking.id}-${field.fieldName}`}
                          style={parseCssSafely(field.valueFieldCss)}
                        >
                          {getFieldValue(field, { booking })}
                        </div>
                      );
                    } else if (field.fieldType === FieldType.Image) {
                      return (
                        <img
                          className="booking-list-value"
                          key={`${booking.id}-${field.fieldName}`}
                          src={getFieldValue(field, { booking })}
                          style={parseCssSafely(field.valueFieldCss)}
                          alt={field.displayName}
                        />
                      );
                    } else {
                      return (
                        "Field type " + field.fieldType + " not recognized"
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>{" "}
    </div>
  );
};

export default BookingList;
