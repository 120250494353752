// BookingDaysRows.tsx
import React, { useEffect, useState } from "react";
import { Calendar, Booking, FieldToShow } from "../../models/ClientLayoutModel";
import {
  getDateRange,
  isBookingOnDate,
  useCurrentTime,
} from "../../helpers/DateHelper";
import {
  getBookingsForDateSorted,
  getFieldValue,
  getPaginationString,
  parseCssSafely,
} from "../../helpers/Utils";
import { useDataContext } from "../../DataContext";

interface RowsWithTablesProps {
  scrollRef: React.RefObject<HTMLDivElement>;
}

const RowsWithTables: React.FC<RowsWithTablesProps> = ({ scrollRef }) => {
  const { getData } = useDataContext();
  const data = getData();
  const [currentPageIndex, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  let dataIndex = -1;

  const maxRows = data.infoStyle.maximumRowsToShow ?? 0;
  const pageChangeInterval = data.infoStyle.eventDisplayDurationInSeconds;

  useEffect(() => {
    const totalBookings = data.bookings.length;
    setTotalPages(maxRows > 0 ? Math.ceil(totalBookings / maxRows) : 1);

    const interval = setInterval(() => {
      setCurrentPage((prev) => (prev + 1) % totalPages);
    }, pageChangeInterval * 1000);

    return () => clearInterval(interval);
  }, [data.bookings, maxRows, pageChangeInterval, totalPages]);

  const paginate = <T,>(items: T[], page: number, pageSize: number): T[] => {
    const start = page * pageSize;
    return items.slice(start, start + pageSize);
  };

  // Function to render calendar header based on rowHeaderDatasetType
  const renderHeader = (date: string) => {
    const dayOfWeekCapitalized =
      new Date(date)
        .toLocaleDateString("da-DK", { weekday: "long" })
        .charAt(0)
        .toUpperCase() +
      new Date(date).toLocaleDateString("da-DK", { weekday: "long" }).slice(1);
    const dateFormatted =
      new Date(date).getDate() + "/" + (new Date(date).getMonth() + 1);

    return (
      <div
        className="day-header"
        style={JSON.parse(data.infoStyle.dayHeaderCss ?? "{}")}
      >
        <div
          className="day-of-week"
          style={JSON.parse(data.infoStyle.dayHeaderDayOfWeekCss ?? "{}")}
        >
          {dayOfWeekCapitalized}
        </div>
        <div
          className="date-formatted"
          style={JSON.parse(data.infoStyle.dayHeaderDateCss ?? "{}")}
        >
          {dateFormatted}
        </div>
      </div>
    );
  };

  const renderTableContent = (
    bookingsForDate: Booking[],
    date: string,
    calendarFromData?: Calendar
  ) => {
    const paginatedBookings =
      maxRows > 0
        ? paginate(bookingsForDate, currentPageIndex, maxRows)
        : bookingsForDate;
    return (
      <div className="table-container">
        {/* Table Header */}
        {!data.infoStyle.mergeBookingsIntoSingleCalendarView &&
          renderTableHeader()}
        {/* Table Content */}
        {paginatedBookings.map((booking) => {
          // Increment the global index if mergeBookingsIntoSingleCalendarView is true
          if (data.infoStyle?.mergeBookingsIntoSingleCalendarView) {
            dataIndex++;
          }

          // Initialize groupedFields as an array of empty arrays with the correct size
          const groupedFields: { fields: FieldToShow[] }[] = new Array(
            Math.max(...data.fieldNamesToDisplay.map((field) => field.order)) +
              1
          )
            .fill(null)
            .map(() => ({ fields: [] }));

          // Group fields by their order
          data.fieldNamesToDisplay.forEach((field) => {
            groupedFields[field.order].fields.push(field);
          });

          return (
            <div
              key={booking.id}
              className="table-row"
              style={{
                ...((
                  data.infoStyle?.mergeBookingsIntoSingleCalendarView
                    ? dataIndex % 2 === 0
                    : (bookingsForDate.indexOf(booking) + 1) % 2 === 0
                )
                  ? JSON.parse(data.infoStyle?.oddsCss ?? "{}")
                  : JSON.parse(data.infoStyle?.evensCss ?? "{}")),
              }}
            >
              {groupedFields.map((fieldGroup, index) => (
                <div className="table-cell" key={index}>
                  {fieldGroup.fields.map(
                    (field, i) =>
                      getFieldValue(field, {
                        booking,
                        calendar:
                          calendarFromData ??
                          data.calendars.find(
                            (c) => c.id === booking.calendarId
                          ) ??
                          ({} as Calendar),
                        date,
                      }) && (
                        <div
                          key={i}
                          style={JSON.parse(field.valueFieldCss ?? "{}")}
                          dangerouslySetInnerHTML={{
                            __html: getFieldValue(field, {
                              booking,
                              calendar:
                                calendarFromData ??
                                data.calendars.find(
                                  (c) => c.id === booking.calendarId
                                ) ??
                                ({} as Calendar),
                              date,
                            }),
                          }}
                        />
                      )
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  const renderCalendars = (calendar: Calendar) => {
    const dateRange = getDateRange();

    // Check if there are any bookings for the entire date range for the calendar
    const hasBookings = dateRange.some((date) =>
      data.bookings.some(
        (booking) =>
          booking.calendarId === calendar.id &&
          isBookingOnDate(new Date(booking.start), new Date(booking.end), date)
      )
    );

    // If there are no bookings and we have a merged view, return null and skip rendering
    if (data.infoStyle.mergeBookingsIntoSingleCalendarView && !hasBookings) {
      return null;
    }

    return (
      <div key={calendar.id} className="rows-with-tables-container">
        {!data.infoStyle.mergeBookingsIntoSingleCalendarView && (
          <div
            className="rows-with-tables-header"
            style={JSON.parse(data.infoStyle.calendarTitleCss ?? "{}")}
          >
            {calendar.name}
          </div>
        )}
        {dateRange.map((date) => {
          const bookingsForDate = getBookingsForDateSorted(date, calendar.id);

          return (
            <div key={date} className="date-container">
              {renderHeader(date)}
              {bookingsForDate.length > 0 &&
                renderTableContent(bookingsForDate, date, calendar)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderBookings = () => {
    const dateRange = getDateRange();

    return (
      <div className="merged-bookings-container">
        {dateRange.map((date) => {
          const bookingsForDateSorted = getBookingsForDateSorted(date);
          return (
            <div key={date} className="date-container">
              {renderHeader(date)}
              {bookingsForDateSorted.length > 0 ? (
                renderTableContent(bookingsForDateSorted, date)
              ) : (
                <div
                  style={parseCssSafely(
                    data.infoStyle.defaultTextWhenNoBookingsCss
                  )}
                >
                  {data.infoStyle.defaultTextWhenNoBookings ?? ""}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTableHeader = () => {
    const seenHeaders: Set<string> = new Set(); // To track unique combinations of order and displayName

    return (
      <div className="table-header">
        {data.fieldNamesToDisplay.map((field) => {
          // Create a unique identifier based on 'order' and 'displayName'
          const headerKey = `${field.order}-${field.displayName}`;
          if (seenHeaders.has(headerKey)) {
            return null; // Skip rendering if this combination of order and displayName is already seen
          }
          seenHeaders.add(headerKey); // Mark this combination as seen
          return (
            <div
              key={field.sourceType + field.fieldName}
              className="table-header-cell"
              style={JSON.parse(field.headerFieldCss ?? "{}")}
            >
              {field.displayName}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className="app"
      style={{
        backgroundImage: data?.infoStyle?.backgroundImageBase64
          ? `url(${data.infoStyle?.backgroundImageBase64})`
          : "{}",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      {
        <div
          className="clock"
          style={JSON.parse(data?.infoStyle?.clockCss ?? "{}")}
        >
          {useCurrentTime().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })}
        </div>
      }
      <div
        className={
          data.infoStyle.autoscrollOnVerticalOverflow
            ? "scrollable-content"
            : ""
        }
        style={JSON.parse(data.infoStyle.backgroundCss ?? "{}")}
        ref={scrollRef}
      >
        <div style={JSON.parse(data.infoStyle.layoutNameCss ?? "{}")}>
          {data.layoutName}
        </div>
        {data.infoStyle.mergeBookingsIntoSingleCalendarView &&
          renderTableHeader()}
        {data &&
        data.calendars.length > 0 &&
        !data.infoStyle.mergeBookingsIntoSingleCalendarView ? (
          data.calendars.map((calendar) => renderCalendars(calendar))
        ) : data && data.infoStyle.mergeBookingsIntoSingleCalendarView ? (
          renderBookings()
        ) : (
          <div>No calendars available.</div>
        )}
      </div>
      {totalPages > 1 && data.infoStyle.eventPaginationString && (
        <div style={parseCssSafely(data.infoStyle.eventPaginationStringCss)}>
          {getPaginationString(currentPageIndex, totalPages)}
        </div>
      )}
    </div>
  );
};

export default RowsWithTables;
