import React from "react";
import { useDataContext } from "../../DataContext";
import { Booking, Calendar, FieldToShow } from "../../models/ClientLayoutModel";
import { parseCssSafely } from "../../helpers/Utils";
import "./CalendarDayView.css";
import { useCurrentTime } from "../../helpers/DateHelper";

const CalendarDayView: React.FC = () => {
  const { layoutName, infoStyle, bookings, calendars, fieldNamesToDisplay } =
    useDataContext().getData();

  // Configuration for the day view (Render a day view from 07:00 by default for 16 slots)
  const startHour = 7;
  const tilesPerRow = infoStyle.bookingTilesPerRow || 16;

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];
  const currentTime = useCurrentTime();

  const personField: FieldToShow | undefined = fieldNamesToDisplay.find(
    (field) => field.fieldName === "Person"
  );

  // Create an array of time slots (one per hour)
  const timeSlots = Array.from({ length: tilesPerRow }, (_, i) => {
    const hour = startHour + i;
    const displayHour = hour % 24; // Display 24 as 0
    const nextDisplayHour = (hour + 1) % 24;
    return {
      hour,
      startLabel: `${displayHour}.00`,
      endLabel: `${nextDisplayHour}.00`,
    };
  });

  // Calculate the current time in decimal hours (e.g. 7:30 = 7.5)
  const currentHourDecimal =
    currentTime.getHours() +
    currentTime.getMinutes() / 60 +
    currentTime.getSeconds() / 3600;

  // Only show the clock line if the current time is within the displayed range.
  const isCurrentTimeVisible =
    currentHourDecimal >= startHour &&
    currentHourDecimal <= startHour + tilesPerRow;

  // Calculate the left offset percentage for the clock line
  // The left 10% is reserved for calendar titles and the rest (90%) is for time slots
  let leftPercentage = 0;
  if (isCurrentTimeVisible) {
    leftPercentage = 10 + ((currentHourDecimal - startHour) / tilesPerRow) * 90;
  }

  const clockStyle = {
    ...parseCssSafely(infoStyle.clockCss),
    left: `${leftPercentage}vw`,
  };

  // Build child->parent map
  const childToParentMap = new Map<number, number>();
  calendars.forEach((cal) => {
    if (
      cal.parentCalendarId !== null &&
      cal.parentCalendarId !== undefined &&
      calendars.some((c) => c.id === cal.parentCalendarId)
    ) {
      childToParentMap.set(cal.id, cal.parentCalendarId);
    }
  });

  // creates a set of IDs for calendars that are used as a parent
  const parentCalendarIds = new Set<number>();
  calendars.forEach((cal) => {
    if (cal.parentCalendarId !== null && cal.parentCalendarId !== undefined) {
      parentCalendarIds.add(cal.parentCalendarId);
    }
  });

  // Filter out the parent calendars
  const calendarsToRender = calendars.filter(
    (cal) => !parentCalendarIds.has(cal.id)
  );

  // Returns a booking if its time interval overlaps the given slot.
  const getBookingForSlot = (
    calendarId: number,
    slotHour: number
  ): Booking | null => {
    // Construct the time slot boundaries using todays date.
    const timeslotStart = new Date(
      `${today}T${slotHour.toString().padStart(2, "0")}:00:00`
    );
    const timeslotEnd = new Date(
      `${today}T${(slotHour + 1).toString().padStart(2, "0")}:00:00`
    );

    // Filter all bookings for the given calendar.
    const bookingsForCalendar = bookings.filter(
      (booking) => booking.calendarId === calendarId
    );

    // Return any booking that overlaps with the timeslot.
    // Overlap condition: booking starts before slot ends AND booking ends after slot starts
    const overlappingBookings = bookingsForCalendar.filter((booking) => {
      const bookingStart = new Date(booking.start);
      const bookingEnd = new Date(booking.end);
      return bookingStart < timeslotEnd && bookingEnd > timeslotStart;
    });
    // If multiple bookings overlap, choose the one that started earliest.
    overlappingBookings.sort(
      (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
    );

    return overlappingBookings.length > 0 ? overlappingBookings[0] : null;
  };

  // Returns the effective booking for a time slot, checking the parent calendar first
  function getEffectiveBookingForSlot(calendarId: number, slotHour: number) {
    const parentId = childToParentMap.get(calendarId);
    if (parentId !== undefined) {
      const parentBooking = getBookingForSlot(parentId, slotHour);
      if (parentBooking) {
        // Parent has a booking => override
        return parentBooking;
      }
    }
    // Otherwise, if no parent or parent has no booking => use child's own booking
    return getBookingForSlot(calendarId, slotHour);
  }

  return (
    <div
      className="calendar-day-view"
      style={{
        position: "relative",
        ...parseCssSafely(infoStyle.backgroundCss),
      }}
    >
      {/* Header row with time labels */}
      <div className="header-row">
        <div className="calendar-title-header">
          <div
            className="layout-name"
            style={parseCssSafely(infoStyle.layoutNameCss)}
          >
            {layoutName}
          </div>
        </div>
        <div className="time-slots-header">
          {timeSlots.map((slot, index) => (
            <div key={index} className="header-cell">
              {slot.startLabel}-{slot.endLabel}
            </div>
          ))}
        </div>
      </div>

      {/* Calendar rows */}
      {calendarsToRender.map((calendar: Calendar) => (
        <div key={calendar.id} className="calendar-row">
          <div
            className="calendar-title"
            style={parseCssSafely(infoStyle.calendarTitleCss)}
          >
            {calendar.name}
          </div>
          <div className="time-slots">
            {timeSlots.map((slot, index) => {
              const booking = getEffectiveBookingForSlot(
                calendar.id,
                slot.hour
              );
              return (
                <div key={index} className="time-slot">
                  {booking ? (
                    // Occupied slot
                    <div
                      className="booking"
                      style={parseCssSafely(infoStyle.bookingContainerCss)}
                    >
                      <div
                        className="booking-person"
                        style={parseCssSafely(personField?.valueFieldCss)}
                      >
                        {booking.person}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="booking-empty"
                      style={parseCssSafely(infoStyle.bookingContainerCss)}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
      {/* Time clock indicator */}
      {isCurrentTimeVisible && (
        <div className="current-time-indicator" style={clockStyle}></div>
      )}
    </div>
  );
};

export default CalendarDayView;
