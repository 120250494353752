import React from "react";
import { getBookingsForDateSorted, getFieldValue } from "../../helpers/Utils";
import { useDataContext } from "../../DataContext";

interface MenuOfTheWeekProps {}

const MenuOfTheWeek: React.FC<MenuOfTheWeekProps> = () => {
  const { getData } = useDataContext();

  // Assuming a single calendar
  const calendar = getData().calendars[0];

  // Get today's date
  const today = new Date();

  const counfOfWeekDaysToDisplay = 5;

  // Calculate the start (Monday) and end (Friday) of the current week
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay() + 1); // Adjust to Monday
  const weekDates = Array.from({ length: counfOfWeekDaysToDisplay }, (_, i) => {
    // Friday included
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    return date;
  });

  return (
    <div
      className="app"
      style={{
        backgroundImage: getData()?.infoStyle?.backgroundImageBase64
          ? `url(${getData().infoStyle?.backgroundImageBase64})`
          : "{}",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div style={JSON.parse(getData().infoStyle.backgroundCss ?? "{}")}>
        {getData().infoStyle?.horizontalLayout ? (
          // Horizontal layout
          weekDates.map((date) => {
            const bookingsForDate = getBookingsForDateSorted(
              date.toString(),
              undefined,
              false
            );
            return (
              <div
                key={date.toISOString()}
                className="menu-of-the-week-item"
                style={{ height: 100 / counfOfWeekDaysToDisplay + "%" }}
              >
                <div
                  className="menu-date"
                  style={JSON.parse(
                    getData().infoStyle.dayHeaderDayOfWeekCss ?? "{}"
                  )}
                >
                  {date.toLocaleDateString("da-DK", { weekday: "long" })}:
                </div>
                <div className="menu-content">
                  {bookingsForDate.map((booking) =>
                    getData().fieldNamesToDisplay.map((field) => (
                      <div key={field.fieldName} className="menu-booking-field">
                        <span
                          className="menu-field-name"
                          style={JSON.parse(field.headerFieldCss ?? "{}")}
                        >
                          {field.displayName}:
                        </span>{" "}
                        <span
                          className="menu-field-value"
                          style={JSON.parse(field.valueFieldCss ?? "{}")}
                        >
                          {getFieldValue(field, {
                            booking,
                            calendar,
                            date: date.toISOString(),
                          })}
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            );
          })
        ) : (
          // Vertical layout
          <div className="menu-vertical-layout">
            {weekDates.map((date) => {
              const bookingsForDate = getBookingsForDateSorted(
                date.toString(),
                undefined,
                false
              );
              return (
                <div key={date.toISOString()} className="menu-vertical-item">
                  <div
                    className="menu-date"
                    style={JSON.parse(
                      getData().infoStyle.dayHeaderDayOfWeekCss ?? "{}"
                    )}
                  >
                    {date.toLocaleDateString("da-DK", { weekday: "long" })}
                  </div>
                  {bookingsForDate.map((booking) => (
                    <div key={booking.id} className="menu-vertical-content">
                      {getData().fieldNamesToDisplay.map((field) => (
                        <div key={field.fieldName} className="menu-field-block">
                          <div
                            className="menu-field-name"
                            style={JSON.parse(field.headerFieldCss ?? "{}")}
                          >
                            {field.displayName}
                          </div>
                          <div
                            className="menu-field-value"
                            style={JSON.parse(field.valueFieldCss ?? "{}")}
                          >
                            {getFieldValue(field, {
                              booking,
                              calendar,
                              date: date.toISOString(),
                            })}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuOfTheWeek;
