import React, {
  Suspense,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Client, ClientGroup } from "../../models/ClientLayoutModel";
import {
  CheckinActionType,
  CheckinStepType,
  VisitorType,
} from "../../models/ModelTypes";
import {
  CheckinStep,
  CheckinStepAction,
  CheckinPrinterPrintline,
} from "../../models/ClientLayoutCheckinModel";
import { useDataContext } from "../../DataContext";
import { getTranslation, parseCssSafely } from "../../helpers/Utils";
import {
  checkInVisitor,
  checkOutVisitor,
} from "../../apiRequests/CheckinRequests";
import dataStore from "../../helpers/DataStore";
import { isLocalhost } from "../../helpers/Constants";
import CompanyLogo from "./CheckinModules/CompanyLogo";
import "./Checkin.css";
import { printCheckinLabel } from "../../apiRequests/PrintRequests";

// To ensure only necessary data is loaded (e.g. to prevent one module using another modules css)
const SelectClientGroupHorizontalWide = React.lazy(
  () => import("./CheckinSteps/SelectClientGroupHorizontalWide")
);
const SelectClientPortraitCards = React.lazy(
  () => import("./CheckinSteps/SelectClientPortraitCards")
);
const SelectVisitorType = React.lazy(
  () => import("./CheckinSteps/SelectVisitorType")
);
const SelectClientGroupGridView = React.lazy(
  () => import("./CheckinSteps/SelectClientGroupGridView")
);
const LanguagePicker = React.lazy(
  () => import("./CheckinModules/LanguagePicker")
);
const Modal = React.lazy(() => import("./CheckinModules/Modal"));
const Clock = React.lazy(() => import("./CheckinModules/Clock"));
const HomeButton = React.lazy(() => import("./CheckinModules/HomeButton"));
const EnterVisitorPhoneNumber = React.lazy(
  () => import("./CheckinSteps/EnterVisitorPhoneNumber")
);
const EnterVisitorName = React.lazy(
  () => import("./CheckinSteps/EnterVisitorName")
);
const EnterCheckoutPhoneNumber = React.lazy(
  () => import("./CheckinSteps/EnterCheckoutPhoneNumber")
);
const EnterVisitorCompanyName = React.lazy(
  () => import("./CheckinSteps/EnterVisitorCompanyName")
);
const SignDocument = React.lazy(() => import("./CheckinSteps/SignDocument"));
const QuestionAndAnswer = React.lazy(
  () => import("./CheckinSteps/QuestionAndAnswer")
);
const ShowConditionsPdf = React.lazy(
  () => import("./CheckinSteps/ShowConditionsPdf")
);
const EnterAppointmentVisitDuration = React.lazy(
  () => import("./CheckinSteps/EnterAppointmentVisitDuration")
);

const Checkin: React.FC = () => {
  const { getData, setLanguage, setCurrentStepId } = useDataContext();

  const [currentCheckinStep, setCurrentCheckinStep] = useState<CheckinStep>(
    getData()?.checkinSteps.find((s) => s.order === 0) ?? ({} as CheckinStep)
  );
  const [clientGroupSelected, setClientGroupSelected] = useState<
    ClientGroup | undefined
  >(undefined);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [modalText, setModalText] = useState<string>("");
  const [modalConfirmActionText, setModalConfirmActionText] =
    useState<string>("");
  const [autoCloseModal, setAutocloseModal] = useState<boolean>(true);
  const [modalActionOnConfirm, setModalActionOnConfirm] = useState<() => void>(
    () => () => {}
  );
  const [modalImageBase64, setModalImageBase64] = useState<string>("");

  const [resetKey, setResetKey] = useState(0); // New state to trigger reset

  const isProcessingCheckin = useRef(false);

  // Base timeout (15 seconds for most screens)
  const BASE_INACTIVITY_TIMEOUT = 15 * 1000;
  // Extended timeout (2 minutes) for PDF conditions screen
  const EXTENDED_INACTIVITY_TIMEOUT = 120 * 1000;

  // Use different timeout based on step type
  const getInactivityTimeout = useCallback(() => {
    if (currentCheckinStep.stepType === CheckinStepType.ShowConditionsPdf) {
      return EXTENDED_INACTIVITY_TIMEOUT;
    }
    return BASE_INACTIVITY_TIMEOUT;
  }, [
    currentCheckinStep.stepType,
    BASE_INACTIVITY_TIMEOUT,
    EXTENDED_INACTIVITY_TIMEOUT,
  ]);

  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const initialStepIdSet = useRef(false);

  // set step and current step id
  const setStep = useCallback(
    (step: CheckinStep) => {
      setCurrentCheckinStep(step);
      if (step && step.id) {
        setCurrentStepId(step.id);
      }
    },
    [setCurrentStepId]
  );

  const onReset = useCallback(
    (onlyResetModal: boolean = false) => {
      console.log("resetting");

      if (onlyResetModal) {
        setModalText("");
        setModalTitle("");
        setIsModalOpen(false);
        setAutocloseModal(true);
        setModalActionOnConfirm(() => () => {});
        setModalImageBase64("");
        return;
      }

      setLanguage(getData().checkinWorkflow.defaultLanguage);
      const initialStep =
        getData().checkinSteps?.find((s) => s.order === 0) ??
        ({} as CheckinStep);
      startTransition(() => {
        setStep(initialStep);
      });
      setClientGroupSelected(undefined);
      setModalText("");
      setModalTitle("");
      setIsModalOpen(false);
      setAutocloseModal(true);
      setModalActionOnConfirm(() => () => {});
      setModalImageBase64("");
      setOnCloseModalAction(() => onReset);
      dataStore.resetVisitorData();
    },
    [getData, setLanguage, setStep]
  );
  //Has to happen after onReset is initialized
  const [onCloseModalAction, setOnCloseModalAction] = useState(() => onReset);

  // Reset to default state
  const resetInactivityTimer = useCallback(() => {
    if (isLocalhost()) return;
    onReset();
    setResetKey((prev) => prev + 1); // Increment reset key to trigger re-render
  }, [onReset]);

  // Handle user interaction
  const handleUserInteraction = useCallback(() => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current); // Clear the existing timer
    }
    inactivityTimer.current = setTimeout(
      resetInactivityTimer,
      getInactivityTimeout()
    ); // Set a new timer with appropriate timeout
  }, [resetInactivityTimer, getInactivityTimeout]);

  useEffect(() => {
    // Set the initial inactivity timer
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = setTimeout(
      resetInactivityTimer,
      getInactivityTimeout()
    );

    // Attach event listeners for user interaction
    window.addEventListener("mousemove", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction);
    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("wheel", handleUserInteraction);
    window.addEventListener("touchstart", handleUserInteraction);
    window.addEventListener("touchmove", handleUserInteraction);

    return () => {
      // Cleanup the timer and event listeners on unmount
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction);
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("wheel", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
      window.removeEventListener("touchmove", handleUserInteraction);
    };
  }, [handleUserInteraction, resetInactivityTimer, getInactivityTimeout]);

  useEffect(() => {
    if (!initialStepIdSet.current) {
      const currentStep = getData()?.checkinSteps.find((s) => s.order === 0);
      if (currentStep && currentStep.id) {
        setCurrentStepId(currentStep.id);
        initialStepIdSet.current = true;
      }
    }
  }, [getData, setCurrentStepId]);

  const onClientGroupSelected = (clientGroupSelected: ClientGroup) => {
    setClientGroupSelected(clientGroupSelected);
  };

  const onClientSelected = (hostSelected: Client) => {
    dataStore.setSelectedHost(hostSelected);
  };

  const onVisitorTypeSelected = (type: VisitorType) => {
    dataStore.setVisitorType(type);
  };

  // Trigger checkIn after state updates
  const handleCheckIn = useCallback(async (): Promise<boolean> => {
    try {
      await checkInVisitor(
        dataStore.getVisitorType(),
        dataStore.getSelectedHost().id,
        dataStore.getVisitorName(),
        dataStore.getVisitorPhoneNumber(),
        dataStore.getVisitorCompanyName(),
        dataStore.getSignatureBase64(),
        dataStore.getQuestionAnswers(),
        dataStore.getAccessExpirationDate(),
        dataStore.getIsRepeatVisitor()
      );
      setAutocloseModal(true);
      return true;
    } catch (error: any) {
      console.log(error);

      setAutocloseModal(false);
      setModalTitle("Could not connect to server");
      setModalText(error.toString());
      setIsModalOpen(true);
      return false;
    }
  }, []);

  // Trigger checkOut after state updates
  const handleCheckOut = useCallback(async () => {
    try {
      await checkOutVisitor(dataStore.getVisitorPhoneNumber());
      setModalText(getTranslation("checkout_modal_successfully_checked_out"));
      setAutocloseModal(true);
      setOnCloseModalAction(() => onReset);
    } catch (error: any) {
      setAutocloseModal(false);
      if (error.message.includes("404")) {
        console.log(error);
        setModalText(
          getTranslation(
            "checkout_modal_appointment_not_found",
            "Your appointment was not found. Did you write the correct number?"
          )
        );
      } else {
        setModalTitle("Could not connect to server");
        setModalText(error.toString());
      }
      setOnCloseModalAction(() => () => setIsModalOpen(false));
      setIsModalOpen(true);
    }
  }, [onReset]);

  const handlePrint = useCallback(async () => {
    const checkinPrintLines: CheckinPrinterPrintline[] | undefined =
      getData()?.checkinPrinterPrintlines;
    const printers = getData()?.checkinPrinters;

    if (
      checkinPrintLines &&
      printers &&
      checkinPrintLines.length > 0 &&
      printers.length > 0
    ) {
      try {
        await printCheckinLabel(checkinPrintLines, printers);
      } catch (printError: any) {
        console.error("Printing failed:", printError);
        return;
      }
    } else {
      console.log("No print content or printers defined, skipping print.");
    }
  }, [getData]);

  const onHandleAction = useCallback(
    (actionKey: string, fallbackActions: CheckinStepAction[] = []) => {
      const stepActions = getData().checkinStepActions;
      let actions = stepActions.filter(
        (a) => a.key === actionKey && a.stepId === currentCheckinStep.id
      );
      if (actions.length === 0) actions = fallbackActions;
      console.log("ActionKey", actionKey);
      console.log("Actions", actions);

      actions.forEach((action) => {
        switch (action.actionType) {
          case CheckinActionType.NavigateToStep:
            if (action.nextStepId) {
              const nextStep =
                getData()?.checkinSteps.find(
                  (s) => s.id === action.nextStepId
                ) ?? ({} as CheckinStep);
              if (!nextStep) {
                onHandleAction("", [
                  {
                    actionType: CheckinActionType.ShowModal,
                    modalConfirmActionTextTranslationKey: "ok_button",
                    modalTitleTranslationKey: "step_not_found_title",
                    key: "",
                    stepId: 0,
                    modalTextTranslationKey: "step_not_found_text",
                  },
                ]);
                return;
              }
              startTransition(() => {
                setStep(nextStep);
              });
            }
            break;
          case CheckinActionType.ShowModal:
            setAutocloseModal(true);
            setModalConfirmActionText(
              getTranslation(action.modalConfirmActionTextTranslationKey ?? "")
            );
            setModalTitle(
              getTranslation(action.modalTitleTranslationKey ?? "")
            );
            setModalText(getTranslation(action.modalTextTranslationKey ?? ""));
            setIsModalOpen(true);
            break;
          case CheckinActionType.CheckIn:
            const confirmCheckInAndPrint = async () => {
              if (isProcessingCheckin.current) {
                return;
              }

              isProcessingCheckin.current = true;
              try {
                const success = await handleCheckIn();
                if (success) {
                  await handlePrint();
                } else {
                  console.log(
                    "confirmCheckInAndPrint: Check-in failed, skipping print."
                  );
                }
              } catch (error) {
                console.error(
                  "confirmCheckInAndPrint: Error during check-in/print process:",
                  error
                );
              } finally {
                isProcessingCheckin.current = false;
                console.log("confirmCheckInAndPrint: Processing finished.");
              }
            };

            setAutocloseModal(true);
            setModalActionOnConfirm(() => confirmCheckInAndPrint);
            setModalConfirmActionText(
              getTranslation(action.modalConfirmActionTextTranslationKey ?? "")
            );
            setModalTitle(
              getTranslation(action.modalTitleTranslationKey ?? "", "")
            );
            setModalText(getTranslation(action.modalTextTranslationKey ?? ""));
            setIsModalOpen(true);
            break;
          case CheckinActionType.CheckOut:
            setAutocloseModal(true);
            handleCheckOut();
            setModalConfirmActionText(
              getTranslation(action.modalConfirmActionTextTranslationKey ?? "")
            );
            setModalTitle(
              getTranslation(action.modalTitleTranslationKey ?? "")
            );
            setModalText(getTranslation(action.modalTextTranslationKey ?? ""));
            setIsModalOpen(true);
            break;
          case CheckinActionType.TriggerEvent:
            console.log(`Triggering event for action key: ${actionKey}`);
            break;
          case CheckinActionType.CheckInRepeatVisitor:
            setAutocloseModal(true);
            handleCheckIn();
            setModalTitle(
              getTranslation(action.modalTitleTranslationKey ?? "")
            );
            setModalText(getTranslation(action.modalTextTranslationKey ?? ""));
            setIsModalOpen(true);
            break;
          default:
            console.warn("Unknown action type:", action.actionType);
            break;
        }
      });
    },
    [
      getData,
      handleCheckIn,
      handleCheckOut,
      handlePrint,
      setStep,
      currentCheckinStep.id,
    ]
  );

  const renderStep = () => {
    const currentStep = getData().checkinSteps?.find(
      (step) => step.id === currentCheckinStep.id
    );

    if (!currentStep) return null;
    console.log("current step", currentStep);

    switch (currentStep.stepType) {
      case CheckinStepType.EntrySelectClientGroupHorizontalImages:
        return (
          <Suspense>
            <SelectClientGroupHorizontalWide
              selectClientGroup={onClientGroupSelected}
              setVisitorType={onVisitorTypeSelected}
              handleAction={onHandleAction}
            />
          </Suspense>
        );
      case CheckinStepType.SelectClientPortraitCards:
        return (
          <Suspense>
            <SelectClientPortraitCards
              key={resetKey} // Use resetKey to force re-render
              selectedClientGroup={clientGroupSelected}
              setClient={onClientSelected}
              handleAction={onHandleAction}
              setVisitorType={onVisitorTypeSelected}
            />
          </Suspense>
        );
      case CheckinStepType.SelectVisitorType:
        return (
          <Suspense>
            <SelectVisitorType
              setVisitorType={onVisitorTypeSelected}
              handleAction={onHandleAction}
            />
          </Suspense>
        );
      case CheckinStepType.SelectClientGroupGridView:
        return (
          <Suspense>
            <SelectClientGroupGridView
              selectClientGroup={onClientGroupSelected}
              handleAction={onHandleAction}
              visitorType={dataStore.getVisitorType()}
              setModalImage={setModalImageBase64}
            />
          </Suspense>
        );
      case CheckinStepType.EnterVisitorName:
        return (
          <EnterVisitorName
            handleAction={onHandleAction}
            isRequired={currentStep.isRequired}
          />
        );
      case CheckinStepType.EnterVisitorPhoneNumber:
        return (
          !dataStore.getVisitorPhoneNumber() && (
            <EnterVisitorPhoneNumber
              handleAction={onHandleAction}
              isRequired={currentStep.isRequired}
            />
          )
        );
      case CheckinStepType.EnterCheckoutPhoneNumber:
        return <EnterCheckoutPhoneNumber handleAction={onHandleAction} />;
      case CheckinStepType.SignDocument:
        return <SignDocument handleAction={onHandleAction} />;
      case CheckinStepType.QuestionAndAnswer:
        return <QuestionAndAnswer handleAction={onHandleAction} />;
      case CheckinStepType.ShowConditionsPdf:
        return <ShowConditionsPdf handleAction={onHandleAction} />;
      case CheckinStepType.EnterAppointmentVisitDuration:
        return <EnterAppointmentVisitDuration handleAction={onHandleAction} />;
      case CheckinStepType.EnterVisitorCompanyName:
        return <EnterVisitorCompanyName handleAction={onHandleAction} />;
      default:
        return <div>Step not recognized.</div>;
    }
  };

  return (
    <div
      className="checkin-root-container"
      style={{
        backgroundImage: getData()?.checkinWorkflowStyle?.backgroundImageBase64
          ? `url(${getData()?.checkinWorkflowStyle?.backgroundImageBase64})`
          : "{}",
        ...parseCssSafely(getData()?.checkinWorkflowStyle?.rootContainerCss),
      }}
    >
      {isModalOpen && (
        <Suspense>
          <Modal
            title={modalTitle}
            messageText={modalText}
            onClose={onCloseModalAction}
            autoClose={autoCloseModal}
            confirmActionMessageText={modalConfirmActionText}
            onConfirm={modalActionOnConfirm}
            imageBase64={modalImageBase64}
          />
        </Suspense>
      )}
      {currentCheckinStep.order === 0 && (
        <Suspense>
          <LanguagePicker />
        </Suspense>
      )}
      {renderStep()}
      {
        <Suspense>
          <Clock />
        </Suspense>
      }
      {currentCheckinStep.order !== 0 && (
        <Suspense>
          <HomeButton resetCheckin={onReset} />
        </Suspense>
      )}
      {getData().checkinWorkflowStyle?.logoBase64 && (
        <Suspense>
          <CompanyLogo />
        </Suspense>
      )}
    </div>
  );
};

export default Checkin;
