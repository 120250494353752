import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Suspense,
} from "react";
import { ClientLayout } from "./models/ClientLayoutModel";
import "./App.css";
import {
  ClientLayoutInfoType,
  ClientLayoutType,
  Language,
} from "./models/ModelTypes";
import { cancelRefreshAt, refreshAt as reloadPageAt } from "./helpers/Utils";
import { getBaseApiUrl } from "./helpers/Constants";
import { useDataContext } from "./DataContext";
import { IndexedDBManager } from "./helpers/IndexedDBWrapper";
import "@fontsource/montserrat/600.css";
import { isOldBrowser } from "./helpers/DetectOldBrowser";
import Spinner from "./Spinner";
const isOld = isOldBrowser();
// To ensure only necessary data is loaded (e.g. to prevent one module using another modules css)
const RowsWithTiles = isOld
  ? require("./layouts/infoscreen/RowsWithTiles").default
  : React.lazy(() => import("./layouts/infoscreen/RowsWithTiles"));
const ColumnsAndRows = isOld
  ? require("./layouts/infoscreen/ColumnsAndRows").default
  : React.lazy(() => import("./layouts/infoscreen/ColumnsAndRows"));
const RowsWithTables = isOld
  ? require("./layouts/infoscreen/RowsWithTables").default
  : React.lazy(() => import("./layouts/infoscreen/RowsWithTables"));
const SingleEvent = isOld
  ? require("./layouts/infoscreen/SingleEventView").default
  : React.lazy(() => import("./layouts/infoscreen/SingleEventView"));
const Wayfinding = isOld
  ? require("./layouts/wayfinding/Wayfinding").default
  : React.lazy(() => import("./layouts/wayfinding/Wayfinding"));
const Checkin = isOld
  ? require("./layouts/checkin/Checkin").default
  : React.lazy(() => import("./layouts/checkin/Checkin"));
const MenuOfTheWeek = isOld
  ? require("./layouts/infoscreen/MenuOfTheWeek").default
  : React.lazy(() => import("./layouts/infoscreen/MenuOfTheWeek"));
const RoomAvailability = isOld
  ? require("./layouts/infoscreen/RoomAvailability").default
  : React.lazy(() => import("./layouts/infoscreen/RoomAvailability"));
const BookingClients = isOld
  ? require("./layouts/infoscreen/BookingClients").default
  : React.lazy(() => import("./layouts/infoscreen/BookingClients"));
const CalendarDayView = isOld
  ? require("./layouts/infoscreen/CalendarDayView").default
  : React.lazy(() => import("./layouts/infoscreen/CalendarDayView"));
const SpecialDays = isOld
  ? require("./layouts/infoscreen/SpecialDays").default
  : React.lazy(() => import("./layouts/infoscreen/SpecialDays"));
const WayfindingClientGroupsOverview = isOld
  ? require("./layouts/infoscreen/WayfindingClientGroupsOverview").default
  : React.lazy(
      () => import("./layouts/infoscreen/WayfindingClientGroupsOverview")
    );
const BookingList = isOld
  ? require("./layouts/infoscreen/BookingList").default
  : React.lazy(() => import("./layouts/infoscreen/BookingList"));

// Fallback in case react is broken on load
let dataIsLoaded = false;

function App() {
  const { setLanguage, getLanguage, setData, getData } = useDataContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const dataRef = useRef<ClientLayout | undefined>();
  dataRef.current = getData();
  const dataFetchingIntervalRef = useRef<number | null>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("layoutid");
  let apiUrl = getBaseApiUrl() + `ClientLayout/guid/${id}`;

  const dataFromServerAsString = useRef<string | null>("");

  useEffect(() => {
    if (isOld) return;
    // Try to get cached data from IndexedDB on mount
    IndexedDBManager.getData()
      .then(({ data: cachedData, timestamp: cachedTimestamp }) => {
        console.log("Found data in cache");

        const isDataFresh =
          cachedData && IndexedDBManager.isDataFresh(cachedTimestamp);
        if (isDataFresh || !getLanguage() || !getData()) {
          if (cachedData) {
            setLanguage(
              cachedData?.checkinWorkflow?.defaultLanguage ?? Language.Danish
            );
            setData(cachedData);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error retrieving from IndexedDB:", error);
      });

    // Linter must ignore here, adding the dependencies it wants will start an infinite loop of fetching data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = useCallback(async () => {
    const timeoutId = setTimeout(() => {
      console.error("Request timed out");
      setError("Request timed out");
      setLoading(false);
      cancelRefreshAt(); // Stop refreshing if server is down
    }, 300000); // 5 minutes timeout on request

    console.log("Fetching data...");
    fetch(apiUrl)
      .then(async (response) => {
        clearTimeout(timeoutId); // Clear the timeout if request completes

        if (!response.ok) {
          console.error("Error fetching data");
          setLoading(false);
          return;
        }

        let tmpDataFromServer = await response.json();
        let tmpDataFromServerAsString = JSON.stringify(tmpDataFromServer);

        if (tmpDataFromServerAsString !== dataFromServerAsString.current) {
          dataFromServerAsString.current = tmpDataFromServerAsString;
          const dataAsJson = tmpDataFromServer as ClientLayout;

          console.log("New data:", tmpDataFromServer);

          // Save to IndexedDB
          await IndexedDBManager.saveData(dataAsJson);

          setLanguage(
            dataAsJson.checkinWorkflow?.defaultLanguage ?? Language.Danish
          );
          setData(dataAsJson);
        } else {
          console.log("No new data");
        }

        setLoading(false);
        setError(null);
        reloadPageAt(1, 0, 0); // Reload page at 01:00:00
        dataIsLoaded = true;
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.error("Request timed out");
        } else {
          setError(error.message);
        }
        setLoading(false);
        cancelRefreshAt(); // Stop refreshing if server is down
      });
  }, [apiUrl, dataFromServerAsString, setData, setLanguage]);

  useEffect(() => {
    if (dataFetchingIntervalRef.current) {
      clearInterval(dataFetchingIntervalRef.current);
    }

    fetchData();
    const intervalSeconds =
      (getData()?.dataFetchingIntervalSeconds ?? 60) * 1000;
    dataFetchingIntervalRef.current = window.setInterval(
      fetchData,
      intervalSeconds
    );

    return () => {
      if (dataFetchingIntervalRef.current) {
        clearInterval(dataFetchingIntervalRef.current);
      }
    };
    // Should only be triggered on timer, not on implicit data change, adding the dependencies it wants will start an infinite loop of fetching data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !scrollRef.current ||
      !getData()?.infoStyle?.autoscrollOnVerticalOverflow
    ) {
      return;
    }

    const scrollElement = scrollRef.current;
    const scrollSpeed =
      getData().infoStyle?.autoscrollOnVerticalOverflowSpeed ?? 20;
    const endPauseSeconds =
      getData().infoStyle?.autoscrollOnVerticalOverflowEndPauseSeconds ?? 3;

    let isScrolling = true;
    let lastFrameTime = 0; // 0 = initial state
    let scrollAmount = 0;
    let animationId: number;
    let timeoutId: NodeJS.Timeout;

    const smoothScroll = (time: number) => {
      if (!isScrolling) return;

      // Initial pause logic
      if (lastFrameTime === 0) {
        lastFrameTime = time;
        timeoutId = setTimeout(() => {
          // Reset flags and restart animation
          lastFrameTime = performance.now(); // Reset timestamp
          isScrolling = true; // Re-enable scrolling
          requestAnimationFrame(smoothScroll);
        }, endPauseSeconds * 1000);
        isScrolling = false; // Pause during timeout
        return;
      }

      // Normal scroll logic
      const deltaTime = time - lastFrameTime;
      lastFrameTime = time;
      scrollAmount += (scrollSpeed * deltaTime) / 1000;
      scrollElement.scrollTop = scrollAmount;

      // Handle end-of-scroll
      if (
        scrollElement.scrollTop + scrollElement.clientHeight >=
        scrollElement.scrollHeight
      ) {
        isScrolling = false;
        timeoutId = setTimeout(() => {
          scrollAmount = 0;
          scrollElement.scrollTop = 0;
          lastFrameTime = 0; // Reset for initial pause
          isScrolling = true; // Re-enable scrolling
          requestAnimationFrame(smoothScroll);
        }, endPauseSeconds * 1000);
        return;
      }

      animationId = requestAnimationFrame(smoothScroll);
    };

    animationId = requestAnimationFrame(smoothScroll);

    return () => {
      isScrolling = false;
      clearTimeout(timeoutId);
      cancelAnimationFrame(animationId);
    };
  }, [getData]);

  const renderContent = (data: ClientLayout) => {
    try {
      switch (data.clientLayoutType) {
        case ClientLayoutType.Info:
          switch (data.infoStyle.clientLayoutInfoType) {
            case ClientLayoutInfoType.RowsWithTiles:
              return (
                <Suspense fallback={<div></div>}>
                  <RowsWithTiles scrollRef={scrollRef} />{" "}
                </Suspense>
              );
            case ClientLayoutInfoType.ColumnsAndRow:
              return (
                <Suspense fallback={<div></div>}>
                  <ColumnsAndRows />
                </Suspense>
              );
            case ClientLayoutInfoType.RowsWithTables:
              return (
                <Suspense fallback={<div></div>}>
                  <RowsWithTables scrollRef={scrollRef} />
                </Suspense>
              );
            case ClientLayoutInfoType.SingleEvent:
              return (
                <Suspense fallback={<div></div>}>
                  <SingleEvent />
                </Suspense>
              );
            case ClientLayoutInfoType.MenuOfTheWeek:
              return (
                <Suspense fallback={<div></div>}>
                  <MenuOfTheWeek />
                </Suspense>
              );
            case ClientLayoutInfoType.RoomAvailability:
              return (
                <Suspense fallback={<div></div>}>
                  <RoomAvailability />
                </Suspense>
              );
            case ClientLayoutInfoType.BookingClients:
              return (
                <Suspense fallback={<div></div>}>
                  <BookingClients />
                </Suspense>
              );
            case ClientLayoutInfoType.CalendarDayView:
              return (
                <Suspense fallback={<div></div>}>
                  <CalendarDayView />
                </Suspense>
              );
            case ClientLayoutInfoType.SpecialDays:
              return (
                <Suspense fallback={<div></div>}>
                  <SpecialDays />
                </Suspense>
              );
            case ClientLayoutInfoType.WayfindingClientGroupsOverview:
              return (
                <Suspense fallback={<div></div>}>
                  <WayfindingClientGroupsOverview />
                </Suspense>
              );
            case ClientLayoutInfoType.BookingList:
              return (
                <Suspense fallback={<div></div>}>
                  <BookingList scrollRef={scrollRef} />
                </Suspense>
              );
            default:
              console.log("NYI");
              return <p>{data.clientLayoutType} is not supported yet</p>;
          }
        case ClientLayoutType.Wayfinding:
          return (
            <Suspense fallback={<div></div>}>
              <Wayfinding />
            </Suspense>
          );
        case ClientLayoutType.Checkin:
          return (
            <Suspense fallback={<div></div>}>
              <Checkin />
            </Suspense>
          );
        default:
          return <></>;
      }
    } catch (error: any) {
      return <p>Error: {error}</p>;
    }
  };

  return (
    <>
      {loading && <Spinner />}
      {!getData() && error && <p>Error: {error}</p>}
      {getData() && renderContent(getData())}
    </>
  );
}

setTimeout(() => {
  const now = new Date(); // Current time
  const future = new Date(now.getTime() + 10 * 1000); // 10 seconds from now

  if (!dataIsLoaded) {
    console.log("Reloading in 10 seconds...");
    reloadPageAt(future.getHours(), future.getMinutes(), future.getSeconds());
  }
}, 360000); //Reload if we do not have data within 6 minutes

export default App;
