import React from "react";
import "./WayfindingClientGroupsOverview.css";
import { useDataContext } from "../../DataContext";
import { getFieldValue, parseCssSafely } from "../../helpers/Utils";
import { FieldType } from "../../models/ModelTypes";

interface BookingClientsProps {}

const WayfindingClientGroupsOverview: React.FC<BookingClientsProps> = () => {
  const { getData } = useDataContext();
  const { clientGroups, fieldNamesToDisplay } = getData();

  const maxRows = getData().infoStyle.maximumRowsToShow ?? 25;
  const maxColumns = getData().infoStyle.maximumColumnsToShow ?? 4;

  // Utility to split array into chunks
  const chunkedGroups = [];
  for (
    let i = 0;
    i < Math.min(clientGroups.length, maxRows * maxColumns);
    i += maxRows
  ) {
    chunkedGroups.push(clientGroups.slice(i, i + maxRows));
    if (chunkedGroups.length >= maxColumns) break;
  }

  return (
    <div className="clientgroup-table-container">
      {chunkedGroups.map((groupChunk, columnIndex) => (
        <div className="clientgroup-column" key={columnIndex}>
          {groupChunk.map((clientGroup) => (
            <div
              className="clientgroup-row"
              style={{
                height: `calc(${100 / maxRows}vh)`,
              }}
              key={clientGroup.id}
            >
              {fieldNamesToDisplay.map((field) => (
                <React.Fragment key={`${clientGroup.id}-${field.displayName}`}>
                  <div style={parseCssSafely(field.headerFieldCss)}>
                    {field.displayName}:
                  </div>
                  <div
                    className="clientgroup-value-field"
                    style={parseCssSafely(field.valueFieldCss)}
                  >
                    {field.fieldType === FieldType.Image
                      ? getFieldValue(field, { clientGroup }) && (
                          <img
                            src={getFieldValue(field, { clientGroup })}
                            alt={field.displayName}
                          />
                        )
                      : getFieldValue(field, { clientGroup })}
                  </div>
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default WayfindingClientGroupsOverview;
