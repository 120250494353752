import React from "react";
import "./CompanyLogo.css";
import { useDataContext } from "../../../DataContext";

interface Props {}

const CompanyLogo: React.FC<Props> = () => {
  const { getData } = useDataContext();
  return (
    <div className="company-logo-container">
      <img
        className="company-logo"
        src={getData().checkinWorkflowStyle?.logoBase64}
        alt="Company logo"
      />
    </div>
  );
};

export default CompanyLogo;
