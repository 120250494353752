import { ClientLayout } from "../models/ClientLayoutModel";

export class IndexedDBManager {
  private static readonly DB_NAME = "clientLayoutDB";
  private static readonly DB_VERSION = 1;
  private static readonly STORE_NAME = "clientLayoutStore";
  private static readonly DATA_KEY = "clientLayoutData";
  private static readonly TIMESTAMP_KEY = "clientLayoutDataTimestamp";

  // Open (or create) the database
  private static openDatabase(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      const request: IDBOpenDBRequest = window.indexedDB.open(
        this.DB_NAME,
        this.DB_VERSION
      );

      request.onerror = (event: Event) => {
        const target = event.target as IDBOpenDBRequest;
        reject("Database error: " + (target.error?.message || "Unknown error"));
      };

      request.onsuccess = (event: Event) => {
        const target = event.target as IDBOpenDBRequest;
        resolve(target.result);
      };

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const target = event.target as IDBOpenDBRequest;
        const db = target.result;
        if (!db.objectStoreNames.contains(this.STORE_NAME)) {
          db.createObjectStore(this.STORE_NAME);
        }
      };
    });
  }

  // Save data to IndexedDB
  public static saveData(data: ClientLayout): Promise<void> {
    return this.openDatabase().then((db) => {
      return new Promise<void>((resolve, reject) => {
        const tx = db.transaction(this.STORE_NAME, "readwrite");
        const store = tx.objectStore(this.STORE_NAME);
        store.put(data, this.DATA_KEY);
        store.put(Date.now(), this.TIMESTAMP_KEY);
        tx.oncomplete = () => resolve();
        tx.onerror = (event: Event) => {
          const target = event.target as IDBRequest;
          reject(target.error);
        };
      });
    });
  }

  // Retrieve data from IndexedDB
  public static getData(): Promise<{
    data: ClientLayout | null;
    timestamp: number;
  }> {
    return this.openDatabase().then((db) => {
      return new Promise<{ data: any | null; timestamp: number }>(
        (resolve, reject) => {
          const tx = db.transaction(this.STORE_NAME, "readonly");
          const store = tx.objectStore(this.STORE_NAME);
          const dataRequest = store.get(this.DATA_KEY);
          const timestampRequest = store.get(this.TIMESTAMP_KEY);

          dataRequest.onerror = (event: Event) => {
            const target = event.target as IDBRequest;
            reject(target.error);
          };

          timestampRequest.onerror = (event: Event) => {
            const target = event.target as IDBRequest;
            reject(target.error);
          };

          dataRequest.onsuccess = () => {
            timestampRequest.onsuccess = () => {
              resolve({
                data: dataRequest.result,
                timestamp: timestampRequest.result,
              });
            };
          };
        }
      );
    });
  }

  // Clear stored data
  public static clearData(): Promise<void> {
    return this.openDatabase().then((db) => {
      return new Promise<void>((resolve, reject) => {
        const tx = db.transaction(this.STORE_NAME, "readwrite");
        const store = tx.objectStore(this.STORE_NAME);
        const clearRequest = store.clear();
        clearRequest.onsuccess = () => resolve();
        clearRequest.onerror = (event: Event) => {
          const target = event.target as IDBRequest;
          reject(target.error);
        };
      });
    });
  }

  // Check if stored data is fresh (default maxAge is 24 hours)
  public static isDataFresh(
    timestamp: number,
    maxAge: number = 24 * 60 * 60 * 1000
  ): boolean {
    return Date.now() - timestamp < maxAge;
  }
}
