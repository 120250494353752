export function isOldBrowser(): boolean {
  return isOldSafari() || isOldChromium();
}

export function isOldSafari(): boolean {
  const ua = navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(ua);

  if (isSafari) {
    const match = ua.match(/Version\/([\d.]+)/); // Safari includes "Version/X.Y.Z"
    if (match && match[1]) {
      const version = parseFloat(match[1]);
      return version < 9;
    }
  }

  return false;
}
export function isOldChromium(): boolean {
  const ua = navigator.userAgent;
  const chromeMatch = ua.match(/Chrom(e|ium)\/([0-9]+)\./);

  if (chromeMatch && chromeMatch[2]) {
    const version = parseInt(chromeMatch[2], 10);
    return version < 55;
  }

  return false;
}
