import { PrintRequest, PrintLine } from "../models/PrintModel";
import dataStore from "../helpers/DataStore";
import { Client } from "../models/ClientLayoutModel";
import {
  CheckinPrinter,
  CheckinPrinterPrintline,
} from "../models/ClientLayoutCheckinModel";
import { PrinterSourceType } from "../models/ModelTypes";

export const printLabel = async (printData: PrintRequest): Promise<void> => {
  try {
    const response = await fetch(printData.LocalPrinterServiceUrl ?? "", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(printData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Print request failed: ${response.status} ${response.statusText} - ${errorText}`
      );
    }
  } catch (error) {
    console.error("Error sending print request:", error);
    throw error;
  }
};

export const printCheckinLabel = async (
  checkinPrintLines: CheckinPrinterPrintline[],
  printers: CheckinPrinter[]
): Promise<void> => {
  if (!checkinPrintLines || checkinPrintLines.length === 0) {
    console.log("No print content found.");
    return;
  }
  if (!printers || printers.length === 0) {
    console.warn("No printers found.");
    return;
  }

  const primaryPrinter =
    printers.find((p) => p.isCheckinWorkFlowPrimary) ?? printers[0];

  const mappedPrintLines: PrintLine[] = checkinPrintLines.map(
    (line: CheckinPrinterPrintline): PrintLine => {
      const processedContent =
        line.sourceType === PrinterSourceType.Text && line.content
          ? replacePlaceholders(line.content)
          : line.content;

      return {
        SourceType: line.sourceType,
        Font: line.font,
        Color: line.color,
        FormatFlag: line.formatFlag,
        HorizontalAlignment: line.horizontalAlignment,
        VerticalAlignment: line.verticalAlignment,
        X_Coordinate: line.x_Coordinate,
        Y_Coordinate: line.y_Coordinate,
        FontSize: line.fontSize,
        IsImage: line.isImage,
        ImageWidth: line.imageWidth,
        ImageHeight: line.imageHeight,
        LineCount: line.lineCount,
        LineWidth: line.lineWidth,
        Content: processedContent,
      };
    }
  );

  const printRequest: PrintRequest = {
    PrinterType: primaryPrinter.printerType,
    PrintLines: mappedPrintLines,
    LocalPrinterServiceUrl: primaryPrinter.localPrinterServiceUrl,
  };

  await printLabel(printRequest);
};

const replacePlaceholders = (content: string): string => {
  return content.replace(/{(\w+)}/g, (match, placeholderKey) => {
    switch (placeholderKey) {
      case "visitor_name":
        return dataStore.getVisitorName() ?? "";
      case "visitor_company_name":
        return dataStore.getVisitorCompanyName() ?? "";
      case "visitor_phone_number":
        return dataStore.getVisitorPhoneNumber() ?? "";
      case "host_name": {
        const host: Client | null = dataStore.getSelectedHost();
        return host?.name ?? "";
      }
      case "currentDateTime":
        return new Date().toLocaleString();
      case "currentDate":
        return new Date().toLocaleDateString();
      case "currentTime":
        return new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      default:
        console.warn(`Unknown print placeholder: ${match}`);
        return match;
    }
  });
};
